/* eslint-disable max-len */
import {
    HttpEvent,
    HttpHandlerFn,
    HttpInterceptorFn,
    HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const versionInterceptor: HttpInterceptorFn = (request: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
    const headers = request.headers.append('X-App-Version', 'debug');

    const requestClone = request.clone({
        headers,
    });
    return next(requestClone).pipe(map((e) => e));
};