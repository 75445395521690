import { Component } from '@angular/core';

//TODO CANVIAR LA URL
const URL_IOS_STORE = 'itms-apps://apps.apple.com/es/app/'; //mi-registro/id1550976884';
const URL_GOOGLE_STORE = 'https://play.google.com/store/apps/'; //details?id=com.miregistro.app';

@Component({
  selector: 'app-api-error-modal',
  templateUrl: './api-error-modal.component.html',
  styleUrls: ['./api-error-modal.component.scss'],
  standalone: true,
  imports: [/*IonContent, IonButton]*/]
})
export class ApiErrorModalComponent {
  // @Input() type: string = 'maintenance';
  // pltfrm: string = 'android';

  // constructor(
  //   private modalCntrl: ModalController,
  //   private platform: Platform
  // ) {
  //   if (this.platform.is('android')) {
  //     this.pltfrm = 'android';
  //   } else if (this.platform.is('ios')) {
  //     this.pltfrm = 'ios';
  //   }
  // }

  // ngOnInit() {
  // }

  // close() {
  //   this.modalCntrl.dismiss();
  // }

  // openStore() {
  //   if (this.platform.is('android')) {
  //     //Android
  //     window.open(URL_GOOGLE_STORE);
  //   } else if (this.platform.is('ios')) {
  //     //iOS
  //     window.open(URL_IOS_STORE);
  //   }
  // }
}
